import logo from './svgviewer-output.svg';
import './App.css';
import Logo from './Logo';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Logo/>
        <p>
         We are working on a new page for you. Stay tuned for updates!
        </p>
      </header>
    </div>
  );
}

export default App;

