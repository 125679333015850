import * as React from "react";

const Logo = (props) => (
  <svg
    width={395.99999999999994}
    height={139.04960755770145}
    viewBox="0 0 340 119.38602669095582"
    className="looka-1j8o68f"
    {...props}
  >
    <defs id="SvgjsDefs2276" />
    <g
      id="SvgjsG2277"
      featurekey="symbolContainer"
      transform="matrix(1,0,0,1,0,10)"
      fill="#f0f0f0"
    >
      <rect
        xmlns="http://www.w3.org/2000/svg"
        width={120}
        height={99}
        rx={10}
        ry={10}
      />
    </g>
    <g
      id="SvgjsG2278"
      featurekey="uHI19F-0"
      transform="matrix(0.8888787918295877,0,0,0.8888787918295877,16.012493924103985,12.563537299770935)"
      fill="#ffffff"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffffff"
        d="M94.487,42.836c0-0.296-0.161-0.569-0.419-0.715l-2.752-1.555l2.759-1.591  c0.256-0.147,0.414-0.422,0.412-0.718c0-0.296-0.161-0.569-0.419-0.715L62.54,19.725c-0.254-0.146-0.564-0.145-0.819,0.003  L4.901,52.489c-0.257,0.148-0.414,0.423-0.413,0.719s0.161,0.569,0.419,0.715l2.75,1.555l-2.757,1.589  c-0.257,0.147-0.414,0.423-0.413,0.718c0.001,0.296,0.161,0.569,0.419,0.715l2.751,1.555l-2.757,1.591  c-0.257,0.147-0.414,0.422-0.413,0.718s0.161,0.569,0.419,0.715l2.751,1.555l-2.758,1.591c-0.257,0.147-0.414,0.422-0.413,0.718  s0.161,0.569,0.419,0.715l31.526,17.819c0.126,0.072,0.266,0.108,0.406,0.108c0.143,0,0.285-0.037,0.413-0.111l23.499-13.549  c0.077-0.018,0.16-0.021,0.232-0.062l11.507-6.618c0.089-0.052,0.148-0.133,0.211-0.21l21.374-12.323  c0.256-0.148,0.414-0.423,0.412-0.719c0-0.296-0.161-0.569-0.419-0.715l-2.75-1.554l2.757-1.589  c0.256-0.147,0.414-0.422,0.412-0.718c0-0.296-0.161-0.569-0.419-0.715l-2.752-1.557l2.759-1.59  C94.331,43.406,94.489,43.132,94.487,42.836z M62.138,21.393l29.86,16.876l-19.965,11.51L42.187,32.896L62.138,21.393z   M6.978,53.197L29,40.5l29.857,16.874L36.835,70.071L6.978,53.197z M6.978,57.774l2.348-1.354l27.107,15.32  c0.126,0.072,0.266,0.107,0.406,0.107c0.143,0,0.285-0.037,0.413-0.111l22.495-12.969v2.673L36.835,74.65L6.978,57.774z   M6.978,62.352l2.349-1.354l27.106,15.322c0.126,0.072,0.266,0.107,0.406,0.107c0.143,0,0.285-0.037,0.413-0.111l22.495-12.97v2.672  L36.835,79.227L6.978,62.352z M36.835,83.807L6.978,66.93l2.349-1.354l27.106,15.319c0.126,0.072,0.266,0.107,0.406,0.107  c0.143,0,0.285-0.036,0.413-0.111l22.495-12.968v2.674L36.835,83.807z M91.998,52.001L72.905,63.01v-2.672l16.753-9.658  L91.998,52.001z M91.998,47.426L72.905,58.433V55.76l16.751-9.658L91.998,47.426z M72.905,53.855v-2.673l16.752-9.659l2.341,1.322  L72.905,53.855z"
      />
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#ffffff"
          d="M16.503,49.738c0.545-0.313,1.14-0.565,1.787-0.754c0.645-0.188,1.258-0.322,1.844-0.401l0.447,0.818   c-0.555,0.085-1.116,0.211-1.682,0.384c-0.574,0.172-1.059,0.376-1.466,0.61c-0.703,0.405-1.146,0.842-1.329,1.312   c-0.179,0.469-0.117,0.934,0.19,1.396l4.031-2.326l0.987,0.571l-4.31,2.488c0.076,0.057,0.16,0.114,0.246,0.167   c0.086,0.061,0.184,0.115,0.29,0.18c0.204,0.117,0.407,0.219,0.609,0.305l4.292-2.481l0.961,0.558l-4.003,2.31   c0.806,0.175,1.625,0.203,2.458,0.084c0.835-0.117,1.596-0.373,2.278-0.766c0.419-0.241,0.775-0.525,1.067-0.854   c0.293-0.325,0.512-0.647,0.659-0.969l1.323,0.311c-0.14,0.338-0.371,0.693-0.698,1.067c-0.328,0.373-0.762,0.719-1.308,1.033   c-0.516,0.296-1.067,0.538-1.655,0.72c-0.588,0.185-1.188,0.308-1.8,0.376c-0.611,0.066-1.225,0.078-1.84,0.039   c-0.615-0.044-1.206-0.142-1.767-0.301l-1.169,0.676l-0.957-0.558l0.949-0.548c-0.098-0.043-0.194-0.092-0.291-0.143   c-0.097-0.05-0.196-0.107-0.305-0.169c-0.106-0.062-0.203-0.121-0.289-0.178c-0.087-0.057-0.168-0.111-0.247-0.168l-0.947,0.549   l-0.989-0.574l1.184-0.684c-0.262-0.332-0.426-0.673-0.498-1.032c-0.069-0.355-0.045-0.713,0.072-1.066   c0.12-0.355,0.335-0.699,0.647-1.037C15.587,50.346,15.994,50.033,16.503,49.738z"
        />
      </g>
    </g>
    <g
      id="SvgjsG2279"
      featurekey="8FbbNb-0"
      transform="matrix(5.685048890045515,0,0,5.685048890045515,132.26833410592346,-26.378626090775274)"
      fill="#ebebeb"
    >
      <path d="M6.52 10.28 c1.4 0 2.5434 0.45998 3.43 1.38 s1.3433 2.12 1.37 3.6 c0 1.48 -0.45334 2.6766 -1.36 3.59 s-2.06 1.3767 -3.46 1.39 c-0.69334 0 -1.34 -0.14666 -1.94 -0.44 s-1.0533 -0.68 -1.36 -1.16 l-0.04 0 l0 7 l-1.8 0 l0 -15.12 l1.8 0 l0 1.36 l0.04 0 c0.36 -0.50666 0.83334 -0.9 1.42 -1.18 s1.22 -0.42 1.9 -0.42 z M3.16 15.26 c0 0.98666 0.28998 1.7833 0.86998 2.39 s1.33 0.91 2.25 0.91 c0.96 0 1.7167 -0.30334 2.27 -0.91 s0.83668 -1.4033 0.85002 -2.39 c0 -0.98666 -0.28334 -1.78 -0.85 -2.38 s-1.33 -0.90666 -2.29 -0.92 c-0.94666 0 -1.7 0.31 -2.26 0.93 s-0.84 1.41 -0.84 2.37 z M18.26 10.28 c0.33334 0 0.6 0.04 0.8 0.12 l-0.08 1.94 c-0.34666 -0.09334 -0.64666 -0.14 -0.9 -0.14 c-1.7733 0 -2.6734 0.98666 -2.7 2.96 l0 4.84 l-1.8 0 l0 -9.48 l1.8 0 l0 1.46 l0.04 0 c0.24 -0.50666 0.62666 -0.91666 1.16 -1.23 s1.0933 -0.47 1.68 -0.47 z M24.92 4.640000000000001 c0.53334 0 0.97998 0.04666 1.34 0.14 l-0.2 1.6 c-0.29334 -0.12 -0.63334 -0.18 -1.02 -0.18 c-0.54666 0 -0.94 0.15 -1.18 0.45 s-0.36 0.83 -0.36 1.59 l0 2.28 l2.14 0 l0 1.56 l-2.16 0 l0 7.92 l-1.8 0 l0 -7.92 l-2.04 0 l0 -1.56 l2.04 0 l0 -2.2 c0 -1.2667 0.25666 -2.1966 0.77 -2.79 s1.3367 -0.89 2.47 -0.89 z M29.200000000000003 10.52 l2.3 3.36 l2.26 -3.36 l2.18 0 l-3.24 4.42 l3.84 5.06 l-2.32 0 l-2.8 -3.98 l-2.8 3.98 l-2.22 0 l3.84 -5.06 l-3.36 -4.42 l2.32 0 z" />
    </g>
  </svg>
);
export default Logo;
